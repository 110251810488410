.button {
	background-color: $secondary;
	border-radius: 50px;
	border: none;
	box-shadow: $shadow-1;
	padding: 2px 20px;
	transition: background-color 150ms $timing-func-1,
		transform 150ms $timing-func-1;

	&__label {
		color: $white;
		display: inline-block;
		font-family: $font-khula;
		font-size: 16px;
		font-weight: $font-bold;
		height: calc(#{$font-16} + 6px);
	}

	&:hover,
	&--active,
	&--increment {
		background-color: $success;
	}

	&--decrement {
		background-color: $danger;

		&:hover {
			background-color: $danger;
		}
	}

	&:active {
		transform: scale(0.95);
	}
}
