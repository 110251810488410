.navbar {
	align-items: center;
	display: flex;
	height: 100px;
	justify-content: space-between;
	padding: 0 $pad-xl;
	width: 100%;

	&__logo {
		width: 160px;

		svg {
			display: block;
		}
	}

	&__creator {
		align-items: center;
		display: inline-flex;
		width: 185px;

		&-info {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			margin-right: 12px;

			&-text,
			&-link {
				font-size: $font-12;
				height: 14px;
			}

			&-link {
				font-weight: $font-semi-bold;
			}

			&-name {
				font-size: $font-16;
				font-weight: $font-bold;
				height: 20px;
				margin: 2px 0;
			}
		}

		&-avatar {
			img {
				height: 62px;
				width: 62px;
			}
		}
	}

	@include is-mobile {
		height: 70px;
		padding: $pad-10;

		&__logo {
			width: 120px;
		}

		&__creator {
			&-info {
				margin-right: 8px;

				&-text,
				&-link {
					font-size: $font-14;
				}
	
				&-name {
					height: 16px;
					margin: 0;
				}
			}

			&-avatar img {
				height: 50px;
				width: 50px;
			}
		}
	}

	@include is-tab {
		padding: 0 $pad-20;
	}

	@include is-tab-landscape {
		padding: 0 $pad-xl;
	}
}
