.loading-screen {
	background-color: $white;
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transition: opacity 0.5s $timing-func-1 3s;
	width: 100%;
	z-index: 100000;

	&__art-wrapper {
		left: 45%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
	}

	&__art {
		width: 100%;

		.sleepy-man {
			animation: breething 2.5s ease infinite;
		}

		.sleep-bubble {
			animation: bubble 2.5s ease infinite;
			transform-origin: 21% 25%;
		}
	}

	&__spin {
		animation: spin 2.5s linear infinite;
		border-radius: 50%;
		border: 10px solid $secondary;
		height: 20vw;
		position: absolute;
		right: -10vw;
		top: -25%;
		width: 20vw;
		z-index: -1;

		&::after {
			animation: color-shuffle 2.5s linear infinite;
			background-color: $secondary;
			border-radius: 50%;
			content: '';
			display: block;
			height: 20%;
			left: 3.33%;
			position: absolute;
			top: 3.33%;
			width: 20%;
		}
	}

	&__text {
		bottom: 0;
		font-size: $font-24;
		font-weight: $font-semi-bold;
		left: 25%;
		position: absolute;

		&::after {
			animation: width-change 2.5s linear infinite;
			content: '........';
			display: inline-block;
			height: 4px;
			line-height: 0;
			margin-left: 5px;
			overflow: hidden;
			width: 0;
		}
	}

	&--hidden {
		opacity: 0;
	}

	&--removed {
		display: none;
	}

	@include is-mobile {
		.loading-screen {
			&__art-wrapper {
				left: 45%;
				width: 70%;
			}

			&__spin {
				border-width: 3px;
				height: 23vw;
				right: -11vw;
				top: -10%;
				width: 23vw;

				&::after {
					height: 30%;
					left: -5%;
					right: -5%;
					width: 30%;
				}
			}

			&__text {
				bottom: -5px;
				font-size: $font-16;

				&::after {
					height: 2px;
				}
			}
		}
	}

	@include is-tab {
		.loading-screen {
			&__art-wrapper {
				left: 45%;
				width: 60%;
			}

			&__spin {
				border-width: 6px;
				height: 22vw;
				right: -11vw;
				top: -20%;
				width: 22vw;

				&::after {
					height: 25%;
					left: 0;
					right: 0;
					width: 25%;
				}
			}

			&__text {
				bottom: -10px;
				font-size: $font-18;

				&::after {
					height: 3px;
				}
			}
		}
	}

	@include is-desktop {
		.loading-screen {
			&__art-wrapper {
				left: 45%;
				width: 50%;
			}

			&__spin {
				border-width: 10px;
				height: 20vw;
				right: -10vw;
				top: -25%;
				width: 20vw;

				&::after {
					height: 20%;
					left: 3.33%;
					right: 3.33%;
					width: 20%;
				}
			}

			&__text {
				bottom: 0;
				font-size: $font-24;

				&::after {
					height: 4px;
				}
			}
		}
	}
}

@keyframes spin {
	0% {
		border-color: $secondary;
		transform: rotate(0deg);
	}

	50% {
		border-color: $success;
	}

	100% {
		border-color: $secondary;
		transform: rotate(360deg);
	}
}

@keyframes color-shuffle {
	0% {
		background-color: $secondary;
	}

	50% {
		background-color: $success;
	}

	100% {
		background-color: $secondary;
	}
}

@keyframes breething {
	0% {
		d: path(
			'M450.256 203.13s-10.275-38.179 31.35-47.6c23.265-5.266 44.74 10.059 56.979 32.511 6.761 13.269 10.723 28.939 10.723 45.534 0 1.514-0.033 3.020-0.098 4.517l0.007-0.213c-0.615 7.207-12.644 11.276 12.423 22.372s70.469 3.27 103.171 14.222 34.042 14.183 58.045 28.103-15.118 2.101 51.498 18.779c24.644 6.172 35.513 8.289 40.382 42.163s4.176 75.131 17.637 82.472c4.826 2.57 10.568 5.056 16.518 7.097l0.861 0.257c7.499 2.826 9.807 3.677 12.607 3.94 0.245 0.029 0.53 0.045 0.818 0.045 1.414 0 2.735-0.398 3.858-1.088l-0.032 0.018s2.889-0.925 2.889 0.633c0 4.62-22.977 3.646-39.022 6.083s-19.129 7.551-25.151 3.677-1.804-8.463-2.461-19.496-1.006-12.024-4.489-30.176c-11.388-45.197-24.426-53.475-24.426-53.475s-118.717 0.084-116.981 0-36.679 0.854-54.468-8.197-23.273-28.448-25.85-5.216c-2.411 21.816-5.059 160.074-5.059 160.074 2.276 1.632 3.8 4.19 4.017 7.112l0.002 0.032c0.359 2.040 0.564 4.39 0.564 6.787 0 2.904-0.301 5.737-0.874 8.47l0.047-0.267c-0.888 4.83-3.225 4.875-4.3 4.11s0-7.173 0-7.173 0.281-5.873-0.775-4.99-0.63 5.778-2.443 11.055c-1.143 3.847-2.782 7.202-4.88 10.214l0.076-0.115c-2.015 2.986-3.383 4.092-3.254 1.839s2.663-5.403 3.769-10.834 2.7-12.032 0.654-10.895-4.108 9.862-7.412 15.759-4.912 8.032-5.802 7.861 2.24-8.549 2.24-8.549 0.911-2.808 2.248-6.566 3.774-8.074 3.11-8.502-2.936 1.726-5.778 6.784-5.143 13.658-5.552 13.453c-0.012-0.354-0.019-0.771-0.019-1.189 0-5.062 1.004-9.889 2.823-14.293l-0.091 0.249c2.626-5.97 6.992-11.015 6.724-11.557s-4.762 4.486-7.803 9.379-3.782 11.218-4.355 10.199c-0.194-1.151-0.305-2.477-0.305-3.829 0-3.799 0.875-7.393 2.435-10.592l-0.063 0.143c2.516-4.987 5.738-9.233 9.594-12.804l0.029-0.027-15.478-217.103z'
		);
	}

	50% {
		d: path(
			'M450.256 203.13s-10.275-38.179 31.35-47.6c23.265-5.266 44.74 10.059 56.979 32.511 6.761 13.269 10.723 28.939 10.723 45.534 0 1.514-0.033 3.020-0.098 4.517l0.007-0.213c-0.615 7.207-12.644 11.276 12.423 22.372s67.146-21.091 107.452-10.506 29.761 38.919 53.775 52.842-15.118 2.101 51.498 18.779c24.644 6.172 35.513 8.289 40.382 42.163s4.176 75.131 17.637 82.472c4.826 2.57 10.568 5.056 16.518 7.097l0.861 0.257c7.499 2.826 9.807 3.677 12.607 3.94 0.245 0.029 0.53 0.045 0.818 0.045 1.414 0 2.735-0.398 3.858-1.088l-0.032 0.018s2.889-0.925 2.889 0.633c0 4.62-22.977 3.646-39.022 6.083s-19.129 7.551-25.151 3.677-1.804-8.463-2.461-19.496-1.006-12.024-4.489-30.176c-11.388-45.197-24.426-53.475-24.426-53.475s-118.717 0.084-116.981 0-36.679 0.854-54.468-8.197-23.273-28.448-25.85-5.216c-2.411 21.816-5.059 160.074-5.059 160.074 2.276 1.632 3.8 4.19 4.017 7.112l0.002 0.032c0.359 2.040 0.564 4.39 0.564 6.787 0 2.904-0.301 5.737-0.874 8.47l0.047-0.267c-0.888 4.83-3.225 4.875-4.3 4.11s0-7.173 0-7.173 0.281-5.873-0.775-4.99-0.63 5.778-2.443 11.055c-1.143 3.847-2.782 7.202-4.88 10.214l0.076-0.115c-2.015 2.986-3.383 4.092-3.254 1.839s2.663-5.403 3.769-10.834 2.7-12.032 0.654-10.895-4.108 9.862-7.412 15.759-4.912 8.032-5.802 7.861 2.24-8.549 2.24-8.549 0.911-2.808 2.248-6.566 3.774-8.074 3.11-8.502-2.936 1.726-5.778 6.784-5.143 13.658-5.552 13.453c-0.012-0.354-0.019-0.771-0.019-1.189 0-5.062 1.004-9.889 2.823-14.293l-0.091 0.249c2.626-5.97 6.992-11.015 6.724-11.557s-4.762 4.486-7.803 9.379-3.782 11.218-4.355 10.199c-0.194-1.151-0.305-2.477-0.305-3.829 0-3.799 0.875-7.393 2.435-10.592l-0.063 0.143c2.516-4.987 5.738-9.233 9.594-12.804l0.029-0.027-15.478-217.103z'
		);
	}

	100% {
		d: path(
			'M450.256 203.13s-10.275-38.179 31.35-47.6c23.265-5.266 44.74 10.059 56.979 32.511 6.761 13.269 10.723 28.939 10.723 45.534 0 1.514-0.033 3.020-0.098 4.517l0.007-0.213c-0.615 7.207-12.644 11.276 12.423 22.372s70.469 3.27 103.171 14.222 34.042 14.183 58.045 28.103-15.118 2.101 51.498 18.779c24.644 6.172 35.513 8.289 40.382 42.163s4.176 75.131 17.637 82.472c4.826 2.57 10.568 5.056 16.518 7.097l0.861 0.257c7.499 2.826 9.807 3.677 12.607 3.94 0.245 0.029 0.53 0.045 0.818 0.045 1.414 0 2.735-0.398 3.858-1.088l-0.032 0.018s2.889-0.925 2.889 0.633c0 4.62-22.977 3.646-39.022 6.083s-19.129 7.551-25.151 3.677-1.804-8.463-2.461-19.496-1.006-12.024-4.489-30.176c-11.388-45.197-24.426-53.475-24.426-53.475s-118.717 0.084-116.981 0-36.679 0.854-54.468-8.197-23.273-28.448-25.85-5.216c-2.411 21.816-5.059 160.074-5.059 160.074 2.276 1.632 3.8 4.19 4.017 7.112l0.002 0.032c0.359 2.040 0.564 4.39 0.564 6.787 0 2.904-0.301 5.737-0.874 8.47l0.047-0.267c-0.888 4.83-3.225 4.875-4.3 4.11s0-7.173 0-7.173 0.281-5.873-0.775-4.99-0.63 5.778-2.443 11.055c-1.143 3.847-2.782 7.202-4.88 10.214l0.076-0.115c-2.015 2.986-3.383 4.092-3.254 1.839s2.663-5.403 3.769-10.834 2.7-12.032 0.654-10.895-4.108 9.862-7.412 15.759-4.912 8.032-5.802 7.861 2.24-8.549 2.24-8.549 0.911-2.808 2.248-6.566 3.774-8.074 3.11-8.502-2.936 1.726-5.778 6.784-5.143 13.658-5.552 13.453c-0.012-0.354-0.019-0.771-0.019-1.189 0-5.062 1.004-9.889 2.823-14.293l-0.091 0.249c2.626-5.97 6.992-11.015 6.724-11.557s-4.762 4.486-7.803 9.379-3.782 11.218-4.355 10.199c-0.194-1.151-0.305-2.477-0.305-3.829 0-3.799 0.875-7.393 2.435-10.592l-0.063 0.143c2.516-4.987 5.738-9.233 9.594-12.804l0.029-0.027-15.478-217.103z'
		);
	}
}

@keyframes bubble {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes width-change {
	0% {
		width: 0;
	}

	50% {
		width: 3ch;
	}

	100% {
		width: 0;
	}
}
