.bottom-bar {
	align-items: center;
	display: flex;
	height: 100px;
	padding: $pad-sm $pad-xl;
	width: 100%;

	.toggle {
		margin-right: 60px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	@include is-mobile {
		height: 70px;
		justify-content: space-between;
		padding: $pad-10;

		.toggle {
			margin-right: 0;

			&:nth-last-of-type(2) {
				margin-right: 0;
			}

			&:last-of-type {
				display: none;
			}
		}
	}

	@include is-tab {
		justify-content: space-between;
		padding: $pad-20;
	}

	@include is-tab-landscape {
		justify-content: flex-start;
		padding: $pad-sm $pad-xl;
	}
}
