*,
*::after,
*::before {
	-webkit-tap-highlight-color: transparent;
	box-sizing: border-box;
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-family: $font-khula;
	font-size: 16px;
	font-weight: $font-normal;

	@include is-mobile {
		font-size: 12px;
	}
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	color: $black;
	line-height: $lh;
}

html,
body {
	height: 100%;
	overflow: hidden;
	position: relative;
}

#root,
.App {
	height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	border: 0;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

:focus {
	outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

code {
	font-family: $font-inconsolata;
	font-size: $font-32;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	-moz-appearance: none;
	-webkit-appearance: none;
}

input[type='search'] {
	-moz-appearance: none;
	-moz-box-sizing: content-box;
	-webkit-appearance: none;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

textarea {
	overflow: auto;
	resize: vertical;
	vertical-align: top;
}

a,
img {
	display: inline-block;
}

a {
	color: $danger;
	text-decoration: none;
	transition: color 0.2s $timing-func-1;

	&:hover {
		color: $secondary;
	}
}

[hidden] {
	display: none;
}

a:focus {
	outline: thin dotted;
}

a:active,
a:hover {
	outline: 0;
}

img {
	-ms-interpolation-mode: bicubic;
	border: 0;
	vertical-align: middle;
}

form {
	margin: 0;
}

button,
input,
select,
textarea {
	*vertical-align: middle;
	font-size: $font-16;
	margin: 0;
	vertical-align: baseline;
}

button,
input {
	line-height: normal;
}

button,
select {
	text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: button;
	*overflow: visible;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
	*height: 13px;
	*width: 13px;
	box-sizing: border-box;
	padding: 0;
}

input[type='search'] {
	-moz-box-sizing: content-box;
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

html,
button,
input,
select,
textarea {
	color: $black;
}

::-moz-selection {
	background-color: $secondary-light;
	text-shadow: none;
}

::selection {
	background-color: $secondary-light;
	text-shadow: none;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

.chromeframe {
	background-color: $secondary-light;
	color: $black;
	margin: 0.2em 0;
	padding: 0.2em 0;
}
