.sidebar {
	background-color: rgba($black, 0.95);
	color: $white;
	overflow: hidden;
	position: absolute;
	transition: transform 300ms $timing-func-1;
	z-index: 1000;

	&__header {
		align-items: center;
		display: flex;
		height: 135px;
		padding: 0 $pad-lg;
		text-transform: capitalize;

		&-title {
			font-size: $font-48;
			font-weight: $font-bold;
			height: calc(#{$font-48} + 18px);
		}

		&-icon {
			cursor: pointer;
			display: block;
			font-size: 30px;
			height: 1em;
			stroke-width: 0;
			transition: transform 250ms $timing-func-1;
			width: 1em;

			&:hover {
				transform: scale(1.3);
			}

			&--rotate {
				fill: $secondary;
				margin-left: auto;
			}

			&--close {
				fill: $danger;
				margin-left: 40px;
			}
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		height: calc(100% - 135px);
		margin-right: -15px;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 0 $pad-lg;

		&-col {
			margin-bottom: 20px;

			&-property {
				display: inline-block;
				margin-bottom: 10px;
			}

			.button {
				margin: 0 20px 20px 0;
			}
		}
	}

	&--bottom {
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		bottom: 0;
		height: 60%;
		left: 0;
		transform: translateY(100%);
		width: 100%;

		.sidebar__body {
			&-col {
				width: 50%;
			}
		}

		&.sidebar--open {
			transform: translateY(0);
		}
	}

	&--right {
		border-top-left-radius: $border-radius;
		bottom: 0;
		height: 100%;
		max-width: 100%;
		right: 0;
		top: 0;
		transform: translateX(100%);
		width: 650px;

		.sidebar__body {
			&-col {
				width: 100%;

				&-property {
					font-size: $font-28;
				}
			}
		}

		&.sidebar--open {
			transform: translateX(0);
		}
	}

	@include is-mobile {
		&__header {
			height: 70px;
			padding: 0 $pad-20;

			&-title {
				font-size: $font-40;
				height: calc(#{$font-40} + 8px);
			}

			&-icon {
				font-size: 20px;

				&--close {
					margin-left: 20px;
				}
			}
		}

		&__body {
			height: calc(100% - 70px - 10px);
			margin-top: 10px;
			padding: 0 $pad-20;

			&-col {
				margin-bottom: 10px;

				&-property {
					display: inline-block;
					margin-bottom: 10px;
				}

				.button {
					margin: 0 15px 20px 0;

					&__label {
						font-size: $font-18;
						height: calc(#{$font-18} + 6px);
					}
				}
			}
		}

		&--right {
			border-top-left-radius: 0;
		}

		&--bottom {
			height: 70%;

			.sidebar__body {
				&-col {
					width: 100%;
				}
			}
		}
	}

	@include is-tab {
		&--bottom {
			.sidebar__body {
				&-col {
					width: 100%;
				}
			}
		}
	}

	@include is-desktop {
		&--bottom {
			.sidebar__body {
				&-col {
					width: 50%;
				}
			}
		}
	}
}
