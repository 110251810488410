.flex-container {
	background-color: $secondary-light;
	height: calc(100% - 200px);
	overflow: auto;
	position: relative;
	transition: padding 300ms $timing-func-1,
		background-color 150ms $timing-func-1;
	cursor: pointer;

	&__share-button.button {
		align-items: center;
		background-color: $danger;
		border-radius: 50%;
		bottom: 120px;
		box-shadow: 0 6px 10px rgba($danger, 0.75);
		display: flex;
		height: 57px;
		justify-content: center;
		padding: 0;
		position: fixed;
		right: 30px;
		width: 57px;

		.button__label {
			font-size: 25px;
			height: auto;

			svg {
				display: block;
				fill: $white;
				height: 1em;
				stroke-width: 0;
				width: 1em;
			}
		}

		&:hover {
			background-color: $danger;
			box-shadow: 0 6px 10px rgba($danger, 0.75);
		}
	}

	&--selected {
		background-color: $success-extra-light;
	}

	&--with-padding {
		padding: $pad-lg $pad-xl;
	}

	@include is-mobile {
		height: calc(100% - 140px);

		&__share-button.button {
			bottom: 85px;
			right: 10px;
		}

		&--with-padding {
			padding: 0;
		}
	}
}
