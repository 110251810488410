.flex-child {
	align-items: center;
	background-color: $primary;
	border-radius: $border-radius;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	margin: 1px;
	min-height: 110px;
	min-width: 110px;
	position: relative;
	transition: background-color 150ms $timing-func-1,
		transform 150ms $timing-func-1;

	&:hover,
	&--selected {
		background-color: $success;
	}

	&:active {
		transform: scale(0.9);
	}

	&__overlay {
		display: block;
		height: 85%;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 90%;
	}

	&__id {
		color: $white;
		font-size: $font-50;
		font-weight: $font-extra-bold;
		height: calc(#{$font-50} + 12px);
	}

	@include is-mobile {
		min-height: 100px;
		min-width: 100px;

		&__overlay {
			height: 85%;
			width: 85%;
		}

		&__id {
			height: calc(#{$font-50} + 8px);
		}
	}

	@include is-tab {
		min-height: 150px;
		min-width: 150px;

		&__overlay {
			height: 85%;
			width: 85%;
		}
	}

	@include is-desktop {
		min-height: 110px;
		min-width: 235px;

		&__overlay {
			height: 85%;
			width: 90%;
		}
	}
}
