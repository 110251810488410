.toast {
	background-color: $white;
	border-radius: 5px;
	bottom: 200px;
	box-shadow: 0px 3px 10px rgba(#132533, 0.29);
	cursor: initial;
	font-size: $font-18;
	padding: 15px;
	position: fixed;
	right: 30px;
	transform-origin: bottom right;
	transform: scale(0);
	transition: transform 0.2s $timing-func-1;
	width: 400px;
	z-index: 500;

	svg {
		height: 1em;
		stroke-width: 0;
		width: 1em;
	}

	&__header {
		display: flex;
		width: 100%;

		&-text {
			color: $black;
			display: inline-block;
			flex-grow: 1;
			font-size: $font-18;
			font-weight: $font-bold;
		}

		&-close {
			cursor: pointer;
			font-size: $font-16;
			transition: transform 0.15s $timing-func-1;

			&:hover {
				transform: scale(1.3);
			}
		}
	}

	&__content {
		align-items: center;
		background-color: rgba($black, 0.05);
		border-radius: inherit;
		cursor: pointer;
		display: flex;
		font-size: $font-16;
		margin-top: 10px;
		padding: 5px;
		transition: transform 0.3s $timing-elastic-func;
		width: 100%;

		&-link {
			color: $success;
			flex-grow: 1;
			font-size: $font-16;
			font-weight: $font-semi-bold;
			height: calc(#{$font-16} + 6px);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&-copy {
			cursor: pointer;
			margin-left: 10px;
		}

		&-check {
			cursor: pointer;
			display: none;
			margin-left: 10px;
		}

		&:active {
			transform: scale(0.9);
		}
	}

	&--active {
		transform: scale(1);
	}

	@include is-mobile {
		bottom: 160px;
		right: 10px;
		width: calc(100% - 20px);
	}
}
