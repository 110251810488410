.axis {
	box-shadow: 0 0 6px $black-light;
	pointer-events: none;
	position: fixed;
	z-index: 100;

	&--horizontal {
		height: 4px;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}

	&--vertical {
		height: calc(100% - 200px);
		left: 50%;
		top: 100px;
		transform: translateX(-50%);
		width: 4px;
	}

	&--main {
		background-color: $danger;
	}

	&--cross {
		background-color: $success;
	}

	@include is-mobile {
		&--vertical {
			height: calc(100% - 140px);
			top: 70px;
		}
	}
}
